function aroundMeMarkersConfig() {
	return [
		{
			default: true,
			anchor: [24, 48],
			popupAnchor: [0, -48],
			size: [48, 48],
			url: "/img/elements/around-me/marker/default.svg"
		},
		{
			anchor: [24, 48],
			identifier: "main",
			popupAnchor: [0, -48],
			size: [48, 48],
			url: "/img/elements/around-me/marker/kaiserlodge.svg"
		},
		{
			anchor: [24, 48],
			identifier: "Restaurant",
			popupAnchor: [0, -48],
			size: [48, 48],
			url: "/img/elements/around-me/marker/restaurant.svg"
		},
		{
			anchor: [24, 48],
			identifier: "restaurant",
			popupAnchor: [0, -48],
			size: [48, 48],
			url: "/img/elements/around-me/marker/restaurant.svg"
		},
		{
			anchor: [24, 48],
			identifier: "Bergrestaurant",
			popupAnchor: [0, -48],
			size: [48, 48],
			url: "/img/elements/around-me/marker/alm.svg"
		},
		{
			anchor: [24, 48],
			identifier: "Mountain restaurant",
			popupAnchor: [0, -48],
			size: [48, 48],
			url: "/img/elements/around-me/marker/alm.svg"
		},
		{
			anchor: [24, 48],
			identifier: "Tourismusverband",
			popupAnchor: [0, -48],
			size: [48, 48],
			url: "/img/elements/around-me/marker/tvb.svg"
		},
		{
			anchor: [24, 48],
			identifier: "Tourist office",
			popupAnchor: [0, -48],
			size: [48, 48],
			url: "/img/elements/around-me/marker/tvb.svg"
		},
		{
			anchor: [24, 48],
			identifier: "Bergbahn",
			popupAnchor: [0, -48],
			size: [48, 48],
			url: "/img/elements/around-me/marker/bergbahn.svg"
		},
		{
			anchor: [24, 48],
			identifier: "Cable car",
			popupAnchor: [0, -48],
			size: [48, 48],
			url: "/img/elements/around-me/marker/bergbahn.svg"
		},
		{
			anchor: [24, 48],
			identifier: "Bar",
			popupAnchor: [0, -48],
			size: [48, 48],
			url: "/img/elements/around-me/marker/bar.svg"
		},
		{
			anchor: [24, 48],
			identifier: "Bar",
			popupAnchor: [0, -48],
			size: [48, 48],
			url: "/img/elements/around-me/marker/bar.svg"
		},
		{
			anchor: [24, 48],
			identifier: "Geschäfte",
			popupAnchor: [0, -48],
			size: [48, 48],
			url: "/img/elements/around-me/marker/shop.svg"
		},
		{
			anchor: [24, 48],
			identifier: "Shops",
			popupAnchor: [0, -48],
			size: [48, 48],
			url: "/img/elements/around-me/marker/shop.svg"
		},
		{
			anchor: [24, 48],
			identifier: "Bushaltestelle",
			popupAnchor: [0, -48],
			size: [48, 48],
			url: "/img/elements/around-me/marker/bus.svg"
		},
		{
			anchor: [24, 48],
			identifier: "Bus stop",
			popupAnchor: [0, -48],
			size: [48, 48],
			url: "/img/elements/around-me/marker/bus.svg"
		},
		{
			anchor: [24, 48],
			identifier: "Modegeschäfte",
			popupAnchor: [0, -48],
			size: [48, 48],
			url: "/img/elements/around-me/marker/mode.svg"
		},
		{
			anchor: [24, 48],
			identifier: "Fashion stores",
			popupAnchor: [0, -48],
			size: [48, 48],
			url: "/img/elements/around-me/marker/mode.svg"
		},
		{
			anchor: [24, 48],
			identifier: "Bergerlebniswelt",
			popupAnchor: [0, -48],
			size: [48, 48],
			url: "/img/elements/around-me/marker/mountain.svg"
		},
		{
			anchor: [24, 48],
			identifier: "Mountain adventure worlds",
			popupAnchor: [0, -48],
			size: [48, 48],
			url: "/img/elements/around-me/marker/mountain.svg"
		},
		{
			anchor: [24, 48],
			identifier: "Schwimmbäder",
			popupAnchor: [0, -48],
			size: [48, 48],
			url: "/img/icons/around-me/marker/schwimmbaeder.svg"
		},
		{
			anchor: [24, 48],
			identifier: "Swimming pools",
			popupAnchor: [0, -48],
			size: [48, 48],
			url: "/img/elements/around-me/marker/schwimmbaeder.svg"
		},
		{
			anchor: [24, 48],
			identifier: "Apotheken",
			popupAnchor: [0, -48],
			size: [48, 48],
			url: "/img/elements/around-me/marker/apotheken.svg"
		},
		{
			anchor: [24, 48],
			identifier: "Pharmacy",
			popupAnchor: [0, -48],
			size: [48, 48],
			url: "/img/elements/around-me/marker/apotheken.svg"
		}
	];
}

export default aroundMeMarkersConfig;
