import React, { Fragment, useContext, useState, Suspense, lazy } from "react";
import cloneDeep from "lodash/cloneDeep";
import map from "lodash/map";
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Container from "../../../helper/container";
import SearchResultContent from "../../../components/search-result-content";
import WebProfileContext from "@WebProfileContext";
import useLocals from "@micado-digital/react-ui/hooks/useLocals";
import localDE from "../../../locales/de.json";
import localEN from "../../../locales/en.json";
import aroundMeMarkersConfig from "../../../utils/aroundMeMarkersConfig";
import aroundMeFilterIcons from "../../../utils/aroundMeFilterIcons";

const ConsentPlaceholder = lazy(() =>
	import(/* webpackChunkName: "consentPlaceholder" */ "../../../components/consentPlaceholder")
);
const DropDown = lazy(() =>
	import(/* webpackChunkName: "reactDropDown" */ "../../../components/dropdown")
);
const OverviewPlan = lazy(() =>
	import(/* webpackChunkName: "reactOverviewPlan" */ "../../../components/overview-plan")
);
const Spacing = lazy(() =>
	import(/* webpackChunkName: "reactSpacing" */ "../../../components/spacing")
);
const Singlteaser = lazy(() =>
	import(/* webpackChunkName: "reactSinglteaser" */ "../../../components/singleteaser")
);
const Highlightbox = lazy(() =>
	import(/* webpackChunkName: "reactHighlightbox" */ "../../../components/highlightbox")
);
const Equipment = lazy(() =>
	import(/* webpackChunkName: "reactEquipment" */ "../../../components/equipment")
);
const DailyRoutine = lazy(() =>
	import(/* webpackChunkName: "reactDailyRoutine" */ "../../../components/daily-routine")
);
const HeadlineTextCombi = lazy(() =>
	import(/* webpackChunkName: "reactHeadlineTextCombi" */ "../../../components/headline-text")
);
const ReactSearchResult = lazy(() =>
	import(/* webpackChunkName: "reactSearchResult" */ "../../../components/ReactSearchResult")
);
const TeaserSlider = lazy(() =>
	import(/* webpackChunkName: "reactTeaserSlider" */ "../../../components/teaser-slider")
);
const RoomOverview = lazy(() =>
	import(/* webpackChunkName: "reactRoomOverview" */ "../../../components/room-overview")
);
const ReactRoutePlanner = lazy(() =>
	import(/* webpackChunkName: "reactRoutePlanner" */ "../../../components/ReactRoutePlanner")
);
const Treatments = lazy(() =>
	import(/* webpackChunkName: "treatments" */ "../../../components/treatments")
);
const BookmarkOutput = lazy(() =>
	import(/* webpackChunkName: "bookmarkOutput" */ "../../../components/bookmark-output")
);
const ButtonElement = lazy(() =>
	import(/* webpackChunkName: "buttonElement" */ "../../../components/button-element")
);
const Quote = lazy(() => import(/* webpackChunkName: "quote" */ "../../../components/quote"));
const BlogDetailInfo = lazy(() =>
	import(/* webpackChunkName: "blogDetailInfo" */ "../../../components/blog-detail-info")
);
const BlogDetailShare = lazy(() =>
	import(/* webpackChunkName: "blogDetailShare" */ "../../../components/blog-detail-share")
);
const BlogOverview = lazy(() =>
	import(/* webpackChunkName: "blogOverview" */ "../../../components/blog-overview")
);
const NewsletterForm = lazy(() =>
	import(/* webpackChunkName: "newsletterForm" */ "../../../components/newsletter-form")
);
const Voucher = lazy(() =>
	import(/* webpackChunkName: "voucher" */ "../../../components/voucher")
);
const ReactSVGIcon = lazy(() =>
	import(/* webpackChunkName: "reactSVGIcon" */ "@micado-digital/react-svg-icon/ReactSVGIcon")
);
const ReactHeadline01 = lazy(() =>
	import(
		/* webpackChunkName: "reactHeadline01" */ "@micado-digital/react-headline/ReactHeadline01"
	)
);
const ReactFile01 = lazy(() =>
	import(/* webpackChunkName: "reactFile01" */ "@micado-digital/react-file/ReactFile01")
);
const ReactFormWeb01 = lazy(() =>
	import(/* webpackChunkName: "reactFormWeb01" */ "@micado-digital/react-form/ReactFormWeb01")
);
const ReactGallery04 = lazy(() =>
	import(
		/* webpackChunkName: "reactGallery04" */ "@micado-digital/react-gallery/ReactGallery04"
	)
);
const ReactGallery03 = lazy(() =>
	import(
		/* webpackChunkName: "reactGallery03" */ "@micado-digital/react-gallery/ReactGallery03"
	)
);
const ReactList01 = lazy(() =>
	import(/* webpackChunkName: "reactList01" */ "@micado-digital/react-list/ReactList01")
);
const ReactText01 = lazy(() =>
	import(/* webpackChunkName: "reactText01" */ "@micado-digital/react-text/ReactText01")
);
const ReactImageText01 = lazy(() =>
	import(
		/* webpackChunkName: "reactImageText01" */ "@micado-digital/react-imagetext/ReactImageText01"
	)
);
const ReactReferences01 = lazy(() =>
	import(
		/* webpackChunkName: "reactReferences01" */ "@micado-digital/react-references/ReactReferences01"
	)
);
const ReactReferences02 = lazy(() =>
	import(
		/* webpackChunkName: "reactReferences02" */ "@micado-digital/react-references/ReactReferences02"
	)
);
const ReactSingleImage01 = lazy(() =>
	import(
		/* webpackChunkName: "singleImage01" */ "@micado-digital/react-singleimage/ReactSingleImage01"
	)
);
const ReactTable01 = lazy(() =>
	import(/* webpackChunkName: "reactTable01" */ "@micado-digital/react-table/ReactTable01")
);
const ReactTeaser01 = lazy(() =>
	import(/* webpackChunkName: "reactTeaser01" */ "@micado-digital/react-teaser/ReactTeaser01")
);
const ReactTeaserList01 = lazy(() =>
	import(
		/* webpackChunkName: "reactTeaserList01" */ "@micado-digital/react-teaser-list/ReactTeaserList01"
	)
);
const ReactVideo02 = lazy(() =>
	import(/* webpackChunkName: "reactVideo02" */ "@micado-digital/react-video/ReactVideo02")
);
const ReactLiftState01 = lazy(() =>
	import(
		/* webpackChunkName: "reactLiftState01" */ "@micado-digital/react-liftstate/ReactLiftState01"
	)
);
const ReactPistState01 = lazy(() =>
	import(
		/* webpackChunkName: "reactPistState01" */ "@micado-digital/react-piststate/ReactPistState01"
	)
);
const ReactWebcams01 = lazy(() =>
	import(
		/* webpackChunkName: "reactWebcams01" */ "@micado-digital/react-webcams/ReactWebcams01"
	)
);
const ReactWeather04 = lazy(() =>
	import(
		/* webpackChunkName: "reactWeather04" */ "@micado-digital/react-weather/ReactWeather04"
	)
);
const ReactSnowReport01 = lazy(() =>
	import(
		/* webpackChunkName: "reactSnowReport01" */ "@micado-digital/react-snowreport/ReactSnowReport01"
	)
);
const ReactCopyrights01 = lazy(() =>
	import(
		/* webpackChunkName: "reactCopyrights01" */ "@micado-digital/react-copyrights/ReactCopyrights01"
	)
);
const ReactEBookTeaser01 = lazy(() =>
	import(
		/* webpackChunkName: "reactEBookTeaser01" */ "@micado-digital/react-ebook-teaser/ReactEBookTeaser01"
	)
);
const ReactAroundMe = lazy(() =>
	import(
		/* webpackChunkName: "reactAroundMe" */ "@micado-digital/react-around-me/ReactAroundMe"
	)
);
const ReactHTML = lazy(() =>
	import(/* webpackChunkName: "reactHTML" */ "@micado-digital/react-html/ReactHTML")
);
const Typography = lazy(() =>
	import(/* webpackChunkName: "materialUITypography" */ "@material-ui/core/Typography")
);
const TourismJobOffersList = lazy(() =>
	import("@micado-digital/react-tourism-job/views/List01")
);

const Elements = ({ items, lang, layoutVariant }) => {
	const [profileData] = useContext(WebProfileContext);
	const [bookmarkValue, setBookmarkValue] = useState(null);
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("md"));
	const l = useLocals({
		lang: lang,
		res: {
			de: localDE,
			en: localEN
		}
	});

	if (!items) return <></>;

	let isInDropdown = false;
	let isDropdownStartTag = false;
	let isDropdownEndTag = false;
	let currentDropdown = null;

	let newElements = cloneDeep(items);

	newElements = newElements.filter(item => {
		if (item.tag === "basic-dropdown") {
			if (item.option === 1) {
				currentDropdown = null;
				isDropdownStartTag = false;
				isDropdownEndTag = true;
				isInDropdown = false;
			} else {
				currentDropdown = item;
				isDropdownStartTag = true;
				isDropdownEndTag = false;
				isInDropdown = true;
			}
		} else {
			isDropdownStartTag = false;
			isDropdownEndTag = false;
		}

		if (isInDropdown && !isDropdownStartTag && currentDropdown) {
			if (currentDropdown._subItems) {
				currentDropdown._subItems.push(item);
			} else {
				currentDropdown._subItems = [item];
			}
		} else if (!isDropdownEndTag) {
			return item;
		}

		return null;
	});

	return (
		<Suspense fallback={<></>}>
			{newElements.map(item => {
				return (
					<React.Fragment key={item.id}>
						{parseElements(
							item,
							lang,
							layoutVariant,
							bookmarkValue,
							setBookmarkValue,
							profileData,
							l,
							isMobile
						)}
					</React.Fragment>
				);
			})}
		</Suspense>
	);
};

const parseElements = (
	item,
	lang,
	layoutVariant,
	bookmarkValue,
	setBookmarkValue,
	profileData,
	l,
	isMobile
) => {
	const { REACT_APP_PATH } = process.env;

	const handleBookmarkValue = value => {
		setBookmarkValue(value);
	};

	switch (item?.tag) {
		case "basic-headline":
			return (
				<Container
					anchor={item?.anchor}
					key={item?.id}
					maxWidth="md"
					name="headline"
					variant={(item?.variant || 0) + 1}
				>
					{item.variant === 6 ? (
						<Typography
							className="mco-headline"
							dangerouslySetInnerHTML={{ __html: item?.text }}
							variant="overline"
						/>
					) : (
						<Suspense fallback={<></>}>
							<ReactHeadline01 text={item?.text} variant={item.variant} />
						</Suspense>
					)}
				</Container>
			);

		case "basic-spacing": {
			return (
				<Container anchor={item?.anchor} key={item?.id} maxWidth="md" name="spacing">
					<Suspense fallback={<></>}>
						<Spacing />
					</Suspense>
				</Container>
			);
		}

		case "basic-text": {
			return (
				<Container
					anchor={item?.anchor}
					key={item?.id}
					maxWidth="md"
					name="text"
					variant={item?.variant}
				>
					<Suspense fallback={<></>}>
						<ReactText01 text={item?.text} />
					</Suspense>
				</Container>
			);
		}

		case "basic-list": {
			return (
				<Container anchor={item?.anchor} key={item?.id} maxWidth="md" name="list">
					<Suspense fallback={<></>}>
						<ReactList01
							headlineVariant="h5"
							icon={
								<ReactSVGIcon color="secondary" src="/img/icons/list-icon.svg" size={24} />
							}
							spacing={2}
							textcontent={item?.textcontent}
							title={item?.title}
							variant={item?.variant}
						/>
					</Suspense>
				</Container>
			);
		}

		case "basic-links": {
			return (
				<Container anchor={item?.anchor} key={item?.id} maxWidth="md" name="links">
					{item.variant === 1 ? (
						<Suspense fallback={<></>}>
							<ReactReferences02
								buttonSize="small"
								buttonVariant="contained"
								elements={item?.elements}
								headlineVariant="h5"
								title={item?.title}
							/>
						</Suspense>
					) : (
						<Suspense fallback={<></>}>
							<ReactReferences01
								elements={item?.elements}
								title={item?.title}
								endIcon={
									<ReactSVGIcon color="primary" src="/img/icons/arrow-link.svg" size={24} />
								}
								hasIcon={false}
								headlineVariant="h5"
							/>
						</Suspense>
					)}
				</Container>
			);
		}

		case "basic-gallery": {
			let breakpoints = {
				0: {
					slidesPerView: 1,
					spaceBetween: 32
				}
			};

			return item?.variant === 1 ? (
				<Container anchor={item?.anchor} key={item?.id} maxWidth="xl" name="gallery">
					<Suspense fallback={<></>}>
						<ReactGallery04
							delay={5000}
							elements={item?.elements}
							navNext="/img/icons/arrow-next.svg"
							navPrev="/img/icons/arrow-prev.svg"
							mediaFormats={{
								xs: "basic-gallery-mobile",
								sm: "basic-gallery-slider"
							}}
							overlayMediaFormats={{
								xs: "basic-gallery"
							}}
							speed={1000}
							showPagination={true}
							showNavigation={true}
							breakpoints={breakpoints}
						/>
					</Suspense>
				</Container>
			) : (
				<Container anchor={item?.anchor} key={item?.id} maxWidth="lg" name="gallery">
					<Suspense fallback={<></>}>
						<ReactGallery03
							breakpoints={{
								xs: 1,
								sm: 2,
								md: 3
							}}
							elements={item?.elements}
							lang={lang}
							mediaFormats={{
								xs: "basic-gallery-mobile",
								sm: "basic-gallery-thumb"
							}}
							overlayMediaFormats={{
								xs: "basic-gallery"
							}}
							spacing={24}
						/>
					</Suspense>
				</Container>
			);
		}

		case "basic-download": {
			return (
				<Container anchor={item?.anchor} key={item?.id} maxWidth="lg" name="download">
					<Suspense fallback={<></>}>
						<ReactFile01 media={item?.media} title={item?.title} />
					</Suspense>
				</Container>
			);
		}

		case "basic-imagetext": {
			return (
				<Container
					anchor={item?.anchor}
					key={item?.id}
					name="imagetext"
					variant={item?.variant}
				>
					<Container maxWidth="xl">
						<Suspense fallback={<></>}>
							<ReactImageText01
								addition2={item?.addition2}
								alt={item?.addition}
								headlineVariant={item?.variant === 1 ? "h1" : "h3"}
								option={item?.option}
								media={item?.media ? item?.media?.[0] : {}}
								mediaFormats={{
									xs: "basic-imagetext-mobile",
									sm: "basic-imagetext"
								}}
								mediaPath={REACT_APP_PATH}
								title={item?.title}
								text={item?.text}
							/>
						</Suspense>
					</Container>
				</Container>
			);
		}

		case "basic-singleimage": {
			let mediaFormats = null;

			if (layoutVariant === "blog") {
				mediaFormats = {
					xs: "basic-singleimage-blog"
				};
			} else {
				mediaFormats = {
					xs: "basic-singleimage-mobile",
					sm: "basic-singleimage"
				};
			}

			return (
				<Container
					anchor={item?.anchor}
					key={item?.id}
					maxWidth={layoutVariant === "blog" ? "md" : "xl"}
					name="singleimage"
				>
					<Suspense fallback={<></>}>
						<ReactSingleImage01
							addition={item?.addition}
							option2={item?.option2}
							media={item?.media ? item?.media?.[0] : {}}
							mediaFormats={mediaFormats}
							mediaPath={REACT_APP_PATH}
							reference={item?.reference}
							title={item?.title}
							text={item?.text}
						/>
					</Suspense>
				</Container>
			);
		}

		case "basic-form": {
			let siteKey = profileData?.spamProtection?.siteKey;
			let type = null;

			if (profileData?.spamProtection?.type === "googlerecaptchav2") {
				type = 2;
			} else if (profileData?.spamProtection?.type === "googlerecaptchav3") {
				type = 3;
			} else {
				type = 1;
			}

			return (
				<Container anchor={item?.anchor} key={item?.id} maxWidth="lg" name="form">
					{item?.elements?.map(element => {
						if (element.tag === "basic-form-bookmark") {
							return (
								<BookmarkOutput key={element?.id} lang={lang} setValue={handleBookmarkValue} />
							);
						}
						return null;
					})}
					<Suspense fallback={<></>}>
						<ReactFormWeb01
							id={item?.id}
							elements={item?.elements?.map(element => {
								if (element.tag === "basic-form-bookmark") {
									return { ...element, tag: "basic-form-hidden", addition: bookmarkValue };
								}
								return element;
							})}
							lang={lang}
							privacyMessage={l("ReactFormWeb01.privacyMessage")}
							spamCheck={type}
							siteKey={siteKey}
						/>
					</Suspense>
				</Container>
			);
		}

		case "basic-singleteaser": {
			if (item?.variant === 1) {
				return (
					<Suspense key={item.id} fallback={<></>}>
						<Highlightbox
							addition={item?.teaser?.addition}
							anchor={item?.anchor}
							categories={item?.teaser?.categories}
							fields={item?.teaser?.fields}
							item={item}
							title={item?.title ? item?.title : item?.teaser?.title}
							text={item?.text ? item?.text : item?.teaser?.text}
							buttonLabelFallback={l("singleteaser.button")}
							link={item?.teaser?.link}
							media={item?.teaser?.media ? item?.teaser?.media?.[0] : {}}
							mediaPath={REACT_APP_PATH}
							variant={item?.variant}
						/>
					</Suspense>
				);
			}

			if (item?.variant === 2) {
				return (
					<Suspense key={item.id} fallback={<></>}>
						<Highlightbox
							addition={item?.teaser?.addition}
							anchor={item?.anchor}
							categories={item?.teaser?.categories}
							fields={item?.teaser?.fields}
							item={item}
							title={item?.title ? item?.title : item?.teaser?.title}
							text={item?.text ? item?.text : item?.teaser?.text}
							buttonLabelFallback={l("singleteaser.button")}
							link={item?.teaser?.link}
							media={item?.teaser?.media ? item?.teaser?.media?.[0] : {}}
							mediaPath={REACT_APP_PATH}
							variant="light"
						/>
					</Suspense>
				);
			}

			return (
				<Container anchor={item?.anchor} key={item?.id} maxWidth="lg" name="singleteaser">
					<Suspense fallback={<></>}>
						<Singlteaser
							addition={item?.teaser?.addition}
							title={item?.title ? item?.title : item?.teaser?.title}
							text={item?.text ? item?.text : item?.teaser?.text}
							buttonLabel={l("singleteaser.button")}
							link={item?.teaser?.link}
							media={item?.teaser?.media ? item?.teaser?.media?.[0] : {}}
							mediaPath={REACT_APP_PATH}
							variant={item?.variant}
						/>
					</Suspense>
				</Container>
			);
		}

		case "basic-external-singleteaser": {
			let button = item?.textcontent?.items.find(e => e.name === "Button")?.text;
			let title = item?.textcontent?.items.find(e => e.name === "Title")?.text;
			let text = item?.textcontent?.items.find(e => e.name === "Text")?.text;
			let link = item?.textcontent?.items.find(e => e.name === "URL")?.text;
			let media = item?.elements?.find(e => e.media);

			return (
				<Container key={item?.id} maxWidth="lg" name="external-singleteaser">
					<Suspense fallback={<></>}>
						<Singlteaser
							title={title}
							text={text}
							buttonLabel={button ? button : l("singleteaser.button")}
							link={link}
							media={media?.media ? media?.media?.[0] : {}}
							mediaPath={REACT_APP_PATH}
							target="_blank"
							variant={item?.variant}
						/>
					</Suspense>
				</Container>
			);
		}

		case "basic-teaser": {
			const marks = item?.teasergroup?.items?.filter(item => {
				return item?.marks;
			});

			if (item?.variant === 1) {
				return (
					<Container
						anchor={item?.anchor}
						maxWidth="xl"
						name={
							marks && marks.length > 0
								? "teaser-list-custom-with-marks"
								: "teaser-list-custom"
						}
					>
						<Suspense fallback={<></>}>
							<TeaserSlider
								counter={true}
								items={item?.teasergroup?.items}
								hasMarks={marks && marks.length > 0 ? true : false}
								lang={lang}
							/>
						</Suspense>
					</Container>
				);
			} else if (item?.variant === 2) {
				return (
					<Container
						anchor={item?.anchor}
						maxWidth="xl"
						name={
							marks && marks.length > 0
								? "teaser-list-custom-with-marks"
								: "teaser-list-custom"
						}
					>
						<Suspense fallback={<></>}>
							<TeaserSlider
								counter={false}
								items={item?.teasergroup?.items}
								hasMarks={marks && marks.length > 0 ? true : false}
								lang={lang}
							/>
						</Suspense>
					</Container>
				);
			} else {
				let breakpoints = {
					0: {
						slidesPerView: 1.1,
						spaceBetween: 16
					},
					600: {
						slidesPerView: 2,
						spaceBetween: 24
					},
					900: {
						slidesPerView: 3,
						spaceBetween: 24
					}
				};

				return (
					<Container anchor={item?.anchor} maxWidth="lg" name="teaser-list">
						<Suspense fallback={<></>}>
							<ReactTeaserList01
								autoplay={false}
								delay={5000}
								headline={item?.title}
								loop={false}
								showPagination={true}
								showNavigation={false}
								sliderBreakpoints={breakpoints}
								speed={1000}
								variant="slider"
							>
								{item?.teasergroup?.items?.map(
									({ id, link, media, title, text, linkTarget, from, to }) => {
										return (
											<ReactTeaser01
												button={l("ReactTeaser01.button")}
												buttonSize="small"
												buttonVariant="text"
												key={id}
												lang={lang}
												link={link}
												linkElement={true}
												media={media ? media?.[0] : {}}
												mediaFormats={{
													xs: "basic-teaser"
												}}
												mediaPath={REACT_APP_PATH}
												target={linkTarget}
												text={text ? text : ""}
												title={title ? title : ""}
											/>
										);
									}
								)}
							</ReactTeaserList01>
						</Suspense>
					</Container>
				);
			}
		}

		case "basic-html":
			return (
				<Container anchor={item?.anchor} key={item?.id} maxWidth="lg" name="html">
					{item?.cookieAccepted ? (
						<Suspense fallback={<></>}>
							<ReactHTML html={item?.text} />
						</Suspense>
					) : (
						<ConsentPlaceholder lang={lang} />
					)}
				</Container>
			);

		case "extension-room-overview": {
			let headline = null;

			item?.textcontent?.items?.filter(item => {
				if (item?.name === "headline") {
					headline = item?.text;
				}
				return null;
			});

			return (
				<Container anchor={item?.anchor} key={item?.id} maxWidth="lg" name="room-overview">
					<Suspense fallback={<></>}>
						<RoomOverview items={item?.elements} headline={headline} lang={lang} />
					</Suspense>
				</Container>
			);
		}

		case "basic-video": {
			return (
				<Container anchor={item?.anchor} key={item?.id} maxWidth="lg" name="video">
					<Suspense fallback={<></>}>
						<ReactVideo02
							media={item?.media[0]}
							mediaFormats={{ xs: "video-mobile", sm: "video" }}
							mediaPath={REACT_APP_PATH}
							title={item?.title}
							text={item?.text}
						/>
					</Suspense>
				</Container>
			);
		}

		case "basic-table": {
			const newTableArray = [];
			const treatments = item?.variant === 2;

			for (const entries of item?.textcontent?.items) {
				const rows = entries?.items;
				const columns = rows.map(column => {
					const columnObj = {
						text: column?.text,
						align: column?.["attr-align"],
						width: column?.["attr-width"]
					};
					return columnObj;
				});
				newTableArray.push(columns);
			}

			if (treatments) {
				return (
					<Container
						anchor={item?.anchor}
						key={item?.id}
						maxWidth={"md"}
						name="table"
						variant={layoutVariant}
					>
						<Suspense fallback={<></>}>
							<Treatments
								addition={item?.addition}
								headlineVariant="h5"
								items={newTableArray}
								id={item?.id}
								title={item?.title}
							/>
						</Suspense>
					</Container>
				);
			} else {
				return (
					<Container
						anchor={item?.anchor}
						key={item?.id}
						maxWidth={item?.variant === 1 ? "lg" : "md"}
						name="table"
						variant={layoutVariant}
					>
						<Suspense fallback={<></>}>
							<ReactTable01
								addition={item?.addition}
								headlineVariant="h5"
								items={newTableArray}
								title={item?.title}
							/>
						</Suspense>
					</Container>
				);
			}
		}

		case "basic-dropdown": {
			return (
				<Container anchor={item?.anchor} key={item?.id} maxWidth="lg" name="dropdown">
					<Suspense fallback={<></>}>
						<DropDown data={item}>{map(item?._subItems, parseElements)}</DropDown>
					</Suspense>
				</Container>
			);
		}

		case "basic-copyrights": {
			return (
				<Container anchor={item?.anchor} key={item?.id} maxWidth="md" name="copyrights">
					<Suspense fallback={<></>}>
						<ReactCopyrights01 />
					</Suspense>
				</Container>
			);
		}

		case "extension-equipment": {
			const variant = item?.variant === 1 && "highlights";

			return (
				<Container anchor={item?.anchor} key={item?.id} maxWidth="lg" name="equipment">
					<Suspense fallback={<></>}>
						<Equipment items={item?.elements} lang={lang} variant={variant}></Equipment>
					</Suspense>
				</Container>
			);
		}

		case "extension-headline-text": {
			let headline = null;
			let text = null;

			item?.textcontent?.items?.filter(item => {
				if (item?.name === "headline") {
					headline = item?.text;
				}
				if (item?.name === "text") {
					text = item?.text;
				}
				return null;
			});

			return (
				<Container anchor={item?.anchor} key={item?.id} maxWidth="lg" name="headline-text">
					<Suspense fallback={<></>}>
						<HeadlineTextCombi headline={headline} text={text}></HeadlineTextCombi>
					</Suspense>
				</Container>
			);
		}

		case "extension-daily-routine": {
			return (
				<Container anchor={item?.anchor} key={item?.id} maxWidth="xl" name="daily-routine">
					<Suspense fallback={<></>}>
						<DailyRoutine data={item} lang={lang} />
					</Suspense>
				</Container>
			);
		}

		case "extension-search-result": {
			return (
				<Container anchor={item?.anchor} key={item?.id} maxWidth="lg" name="search-result">
					<Suspense fallback={<></>}>
						<ReactSearchResult
							api="/searchv2.json.api"
							components={[
								{ identifier: "default", component: <SearchResultContent lang={lang} /> }
							]}
							lang={lang}
							placeholder={l("ReactSearchResult.searchField")}
						/>
					</Suspense>
				</Container>
			);
		}

		case "mski-liftstate": {
			let client = null;
			let region = null;
			let season = null;

			item?.textcontent?.items?.filter(item => {
				if (item.name === "client") {
					return (client = item?.text);
				}

				if (item.name === "region") {
					return (region = item?.text);
				}

				if (item.name === "season") {
					return (season = item?.text);
				}

				return null;
			});

			return (
				<Container anchor={item?.anchor} key={item?.id} maxWidth="lg" name="liftstate">
					<Suspense fallback={<></>}>
						<ReactLiftState01
							client={client}
							filter
							lang={lang}
							region={region}
							season={season}
							skeletonItems={20}
							url={`${REACT_APP_PATH}/SkigebieteManager/Micado.SkigebieteManager.Plugin.FacilityApi/ListFacilities.api`}
						/>
					</Suspense>
				</Container>
			);
		}

		case "mski-piststate": {
			let client = null;
			let region = null;
			let season = null;

			item?.textcontent?.items?.filter(item => {
				if (item.name === "client") {
					return (client = item?.text);
				}

				if (item.name === "region") {
					return (region = item?.text);
				}

				if (item.name === "season") {
					return (season = item?.text);
				}

				return null;
			});

			return (
				<Container anchor={item?.anchor} key={item?.id} maxWidth="lg" name="piststate">
					<Suspense fallback={<></>}>
						<ReactPistState01
							client={client}
							showLegend
							lang={lang}
							region={region}
							season={season}
							skeletonItems={20}
							url={`${REACT_APP_PATH}/SkigebieteManager/Micado.SkigebieteManager.Plugin.FacilityApi/ListFacilities.api`}
						/>
					</Suspense>
				</Container>
			);
		}

		case "mski-webcams": {
			let client = null;
			let region = null;

			item?.textcontent?.items?.filter(item => {
				if (item.name === "client") {
					return (client = item?.text);
				}

				if (item.name === "region") {
					return (region = item?.text);
				}

				return null;
			});

			return (
				<Container anchor={item?.anchor} key={item?.id} maxWidth="lg" name="webcams">
					<Suspense fallback={<></>}>
						<ReactWebcams01
							client={client}
							gridBreakpoints={{
								xs: 12,
								sm: 6,
								md: 4
							}}
							lang={lang}
							live
							overlaySize="xlarge"
							region={region}
							showLocation
							skeletonItems={12}
							url={`${REACT_APP_PATH}/SkigebieteManager/Micado.SkigebieteManager.Plugin.WebcamApi/ListWebcams.api`}
						/>
					</Suspense>
				</Container>
			);
		}

		case "mski-weather": {
			return (
				<Container anchor={item?.anchor} key={item?.id} maxWidth="lg" name="weather">
					<Suspense fallback={<></>}>
						<ReactWeather04
							client="https://sgm.skiwelt.at"
							headline={l("ReactWeather04.headline")}
							headlineVariant="h4"
							lang={lang}
							days={3}
							region="skiwelt"
							url={`${REACT_APP_PATH}/SkigebieteManager/Micado.SkigebieteManager.Plugin.WeatherApi/GetDetails.api`}
							skeletonItems={3}
						/>
					</Suspense>
				</Container>
			);
		}
		case "mski-snowreport": {
			let client = null;
			let region = null;

			item?.textcontent?.items?.filter(item => {
				if (item.name === "client") {
					return (client = item?.text);
				}

				if (item.name === "region") {
					return (region = item?.text);
				}

				return null;
			});

			return (
				<Container anchor={item?.anchor} key={item?.id} maxWidth="md" name="snowreport">
					<Suspense fallback={<></>}>
						<ReactSnowReport01
							client={client}
							identifierAvalanche="avalance-level"
							identifierDateLastSnowfall="date-last-snowfall"
							identifierSnowHeightValley="snow-height-valley"
							identifierSnowHeightMountain="snow-height-mountain"
							lang={lang}
							region={region}
							url={`${REACT_APP_PATH}/SkigebieteManager/Micado.SkigebieteManager.Plugin.PropertyApi/ListProperties.api`}
						/>
					</Suspense>
				</Container>
			);
		}

		case "extension-route-planner": {
			return (
				<Container anchor={item?.anchor} key={item?.id} name="route-planner">
					<Suspense fallback={<></>}>
						<ReactRoutePlanner lang={lang} />
					</Suspense>
				</Container>
			);
		}

		case "extension-button": {
			const label = item?.textcontent?.items.find(e => e.name === "label")?.text;
			const externalLink = item?.textcontent?.items.find(
				e => e.name === "external-link"
			)?.text;
			const link = item?.elements?.[0]?.reference?.pageName;
			const parameter = item?.textcontent?.items.find(e => e.name === "parameter")?.text;

			return (
				<Container anchor={item?.anchor} key={item?.id} maxWidth="md" name="button">
					<Suspense fallback={<></>}>
						<ButtonElement
							label={label}
							link={externalLink ? externalLink : link}
							parameter={parameter}
							target={externalLink ? "_blank" : ""}
						/>
					</Suspense>
				</Container>
			);
		}

		case "extension-quote": {
			const text = item?.textcontent?.items.find(e => e.name === "text")?.text;
			const author = item?.textcontent?.items.find(e => e.name === "author")?.text;

			return (
				<Container anchor={item?.anchor} key={item?.id} maxWidth="md" name="quote">
					<Suspense fallback={<></>}>
						<Quote text={text} author={author} />
					</Suspense>
				</Container>
			);
		}

		case "extension-around-me-map": {
			const categories = item?.textcontent?.items.find(e => e.name === "categories")?.text;
			const headline = item?.textcontent?.items.find(e => e.name === "headline1")?.text;
			const subheadline = item?.textcontent?.items.find(e => e.name === "headline2")?.text;
			const text = item?.textcontent?.items.find(e => e.name === "text")?.text;

			return (
				<Suspense fallback={<></>}>
					<ReactAroundMe
						categories={categories}
						clusterMarker={{
							size: [48, 48],
							url: "/img/elements/around-me/marker/group.svg"
						}}
						filterIcons={aroundMeFilterIcons()}
						headerHeight={!isMobile ? 100 : 80}
						intro={{
							headline: headline,
							subheadline: subheadline,
							text: text
						}}
						lang={lang}
						mediaFormats={{ xs: "infra" }}
						markersConfig={aroundMeMarkersConfig()}
						teaserFallback="/img/fallback.jpg"
					/>
				</Suspense>
			);
		}

		case "blog-detail-info": {
			return (
				<Container anchor={item?.anchor} key={item?.id} maxWidth="md" name="blog-detail-info">
					<Suspense fallback={<></>}>
						<BlogDetailInfo lang={lang} />
					</Suspense>
				</Container>
			);
		}

		case "blog-detail-share": {
			return (
				<Container anchor={item?.anchor} key={item?.id} maxWidth="md" name="blog-detail-share">
					<Suspense fallback={<></>}>
						<BlogDetailShare lang={lang} />
					</Suspense>
				</Container>
			);
		}

		case "blog-overview": {
			return (
				<Container anchor={item?.anchor} key={item?.id} maxWidth="lg" name="blog-overview">
					<Suspense fallback={<></>}>
						<BlogOverview lang={lang} />
					</Suspense>
				</Container>
			);
		}

		case "extension-ebook": {
			let title = item?.textcontent?.items?.find(e => e.name === "title")?.text;
			let text = item?.textcontent?.items?.find(e => e.name === "text")?.text;
			let media = item?.elements?.find(e => e.media)?.media?.[0];
			let filename = item?.elements?.[0]?.media?.find(e => e.filename)?.filename;
			let lastPathName = filename?.substring(filename.lastIndexOf("/") + 1);
			let download = item?.elements?.[0]?.media?.find(e => e.fileType === "PDF")?.filename;
			let browseURL = lastPathName?.replace(/\.[^/.]+$/, "") + `.html`;

			return (
				<Container anchor={item?.anchor} key={item?.id} maxWidth="lg" name="ebook">
					<Suspense fallback={<></>}>
						<ReactEBookTeaser01
							browse={`${REACT_APP_PATH}/ebook/${browseURL}`}
							download={download}
							downloadIcon={"/img/icons/download.svg"}
							lang={lang}
							media={media ? media : {}}
							mediaFormats={{
								xs: "ebook-teaser"
							}}
							text={text}
							title={title}
						/>
					</Suspense>
				</Container>
			);
		}

		case "extension-overview-plan": {
			let animationID = item?.elements?.find(e => e.tag === "animation")?.option3;
			let items = item?.elements?.filter(e => e.tag === "extension-overview-plan-entry");

			return (
				<Container anchor={item?.anchor} key={item?.id} maxWidth="xl" name="overview-plan">
					<Suspense fallback={<></>}>
						<OverviewPlan animationID={animationID} lang={lang} items={items} />
					</Suspense>
				</Container>
			);
		}

		case "extension-newsletter": {
			return (
				<Container anchor={item?.anchor} key={item?.id} maxWidth="md" name="newsletter-form">
					<NewsletterForm lang={lang} />
				</Container>
			);
		}

		case "tourism-job-list": {
			const { textcontent: { items } = {} } = item;

			const categoryID = items?.find(item => item.name === "valCategory")?.text;

			return (
				<Container anchor={item?.anchor} maxWidth="md" key={item?.id} name="job-list">
					<Suspense fallback={<></>}>
						<TourismJobOffersList
							categories={categoryID}
							externalURL="https://www.kaiser-team.at"
							group
						/>
					</Suspense>
				</Container>
			);
		}

		case "extension-voucher": {
			return (
				<Container anchor={item?.anchor} key={item?.id} name="voucher">
					<Voucher
						url={`https://kaiser6351.traumgutscheine.com/start.php?language=${lang}&sub_shop_id=6&incertframe=new`}
					/>
				</Container>
			);
		}

		default:
			if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
				console.log("missing element:", item);
			}

			return null;
	}
};

export default Elements;
