import { useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import MenuContext from "../context/menuContext";

const ChangePage = () => {
	const setMenuOpen = useContext(MenuContext)[1];
	const location = useLocation();

	useEffect(() => {
		setMenuOpen(false);
		window.scrollTo({ top: 0, behavior: "smooth" });
	}, [location, setMenuOpen]);

	return null;
};

export default ChangePage;
